import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Dialog } from "@blueprintjs/core"
import GetStarted from "../components/CTA/GetStarted"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getClientCloudinaryMedia } from "../utils/utils"
import ImageMeta from "../components/ImageMeta"
import CloseIcon from "../components/CloseIcon"

import MarkdownViewer from "@components/MarkdownViewer"

import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

function GridRow(props) {
  if (props.singleItem) {
    return (
      <div className="columns is-desktop community-row">
        {props.items.map((item, i) => {
          const itemLen = props.items.length
          return (
            <>
              <div className="column is-8"></div>
              <div className="column image-button-wrapper">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover-link"
                  href={item.href}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={item.image}
                    width="auto"
                    responsive></ImageMeta>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.href}
                    className="standard-button image-button">
                    Learn More
                  </a>
                </a>
              </div>
              <div className="column is-8"></div>
            </>
          )
        })}
      </div>
    )
  }
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-3"></div>}
            <div
              className={`column image-button-wrapper ${
                itemLen === i + 1 ? "is-8" : "is-8"
              }`}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hover-link"
                href={item.href}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={item.image}
                  width="auto"
                  responsive></ImageMeta>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                  className="standard-button image-button">
                  Learn More
                </a>
              </a>
            </div>
            {i === 0 && <div className="column is-2"></div>}
            {i === 1 && <div className="column is-3"></div>}
          </>
        )
      })}
    </div>
  )
}

function Grid(props) {
  // Split into rows of 2
  var chunk_size = props.rowsOf
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRow
      key={`media-coverage-row-${i}`}
      items={row}
      singleItem={groups.length === 1 && groups[0].length == 1}
    />
  ))
}

function GridRowGallery(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        if (itemLen === 1) {
          return (
            <>
              <div className="column is-2"></div>
              <div className={`column gallery-column first`}>
                <ImageMeta
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"></ImageMeta>
              </div>
              <div
                className={`column gallery-column vis-hidden`}
                style={{ visibility: "hidden" }}>
                <ImageMeta
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"></ImageMeta>
              </div>
              <div
                className={`column gallery-column vis-hidden last`}
                style={{ visibility: "hidden" }}>
                <ImageMeta
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"></ImageMeta>
              </div>
              <div className="column is-2"></div>
            </>
          )
        } else if (itemLen === 2) {
          return (
            <>
              {i === 0 && <div className="column is-2"></div>}
              <div
                className={`column gallery-column ${
                  i === itemLen - 1 ? "last" : i === 0 ? "first" : ""
                } ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}>
                <ImageMeta
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio="16:9"
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"></ImageMeta>
              </div>
              {i === 1 && (
                <div
                  className={`column gallery-column vis-hidden`}
                  style={{ visibility: "hidden" }}>
                  <ImageMeta
                    onClick={() => {
                      props.toggleDialog(i + 3 * props.rowIndex)
                    }}
                    className="gallery-image"
                    cloudName="nuvolum"
                    publicId={item}
                    dpr="auto"
                    aspectRatio="16:9"
                    responsive
                    width="auto"
                    crop="fill"
                    gravity="faces"
                    responsiveUseBreakpoints="true"></ImageMeta>
                </div>
              )}
              {i === 1 && <div className="column is-2"></div>}
            </>
          )
        }
        return (
          <>
            {i === 0 && <div className="column is-2"></div>}
            <div
              className={`column gallery-column ${
                i === itemLen - 1 ? "last" : i === 0 ? "first" : ""
              } ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}>
              <ImageMeta
                onClick={() => {
                  props.toggleDialog(i + 3 * props.rowIndex)
                }}
                className="gallery-image"
                cloudName="nuvolum"
                publicId={item}
                dpr="auto"
                aspectRatio="16:9"
                responsive
                width="auto"
                crop="fill"
                gravity="faces"
                responsiveUseBreakpoints="true"></ImageMeta>
            </div>
            {i === 2 && <div className="column is-2"></div>}
          </>
        )
      })}
    </div>
  )
}

function GridGallery(props) {
  // Split into rows of 2
  var chunk_size = props.rowsOf
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRowGallery
      toggleDialog={props.toggleDialog}
      rowIndex={i - 1}
      key={`gallery-row-${i}`}
      items={row}
    />
  ))
}

function DialogGallery(props) {
  return (
    <ImageGallery
      items={props.images}
      showThumbnails={false}
      showFullscreenButton={false}
      startIndex={props.galleryIndex + 3}
      lazyLoad={false}
    />
  )
}

const SingleCommunity = ({ data, pageContext, location }) => {
  var language
  if (pageContext && pageContext.language) {
    language = pageContext.language
  }
  const post = data.singleCommunity.nodes[0]

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)

  const toggleDialog = prop => {
    setIsDialogOpen(!isDialogOpen)
    setGalleryIndex(prop)
  }

  const handleYearChange = prop => event => {
    setYears({
      ...years,
      [prop]: event.target.checked
    })
  }

  let photos = {}
  let stateYears = {}
  var displayedPhotos = []
  var [years, setYears] = useState()

  if (post.gallery.usesYears) {
    // get photos for set year and create array of photos in photos object
    for (let i = 0; i < post.gallery.years.length; i++) {
      photos[post.gallery.years[i]] = getClientCloudinaryMedia(
        false,
        post.gallery.prefix + post.gallery.years[i]
      )
      stateYears[post.gallery.years[i]] = false
      if (i === post.gallery.years.length - 1)
        stateYears[post.gallery.years[i]] = true
    }
    ;[years, setYears] = useState(stateYears)
    for (let i = 0; i < post.gallery.years.length; i++) {
      let year = post.gallery.years[i]
      if (photos[year] && years[year]) {
        displayedPhotos = displayedPhotos.concat(photos[year])
      }
    }
  } else {
    photos = getClientCloudinaryMedia(false, post.gallery.prefix, "media")
    displayedPhotos = photos
  }

  var reactGalleryPhotos = function (photos) {
    let arr = []
    for (let i = 0; i < photos.length; i++) {
      let obj = {}
      obj.original = `https://res.cloudinary.com/nuvolum/image/upload/q_60,w_1024/v1/${photos[i]}.jpg`
      arr.push(obj)
    }
    return arr
  }

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />

        <div className="single-community">
          <div className="columns top-section white-back">
            <div className="column">
              <h1 className="mobile-left" style={{ textAlign: "center" }}>
                {post.heading}
              </h1>
              <div className="columns">
                <div className={`column is-3`}></div>
                <div className="column">
                  <MarkdownViewer markdown={post.blurb} className="large" />
                </div>
                <div className={`column is-3`}></div>
              </div>
            </div>
          </div>
          {post.mainVideoImagePair && (
            <div
              className="columns has-text-centered"
              style={{ paddingBottom: "4rem" }}>
              <div className="column is-3"></div>
              <div className="column is-full-width">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.mainVideoImagePair.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={true}>
                  <div
                    className="cls__single-community"
                    style={{
                      paddingTop: post.mainVideoImagePair.aspectRatio
                        ? post.mainVideoImagePair.aspectRatio
                        : "89.25%"
                    }}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={post.mainVideoImagePair.imageId}
                      width="auto"
                      responsive></ImageMeta>
                  </div>
                </TheaterVideo>
              </div>
              <div className="column is-3"></div>
            </div>
          )}

          {post.mediaCoverage.images.length > 0 && (
            <section className="body-section color-back media-coverage">
              <div className="columns has-text-centered">
                <div className="column">
                  <h2>{post.mediaCoverage.heading}</h2>
                </div>
              </div>
              <Grid pages={post.mediaCoverage.images} rowsOf={2} />
            </section>
          )}

          <section className="body-section community-gallery">
            <div className="columns has-text-centered">
              <div className="column is-3"></div>
              <div className="column">
                <h2
                  style={{
                    marginTop:
                      post.mediaCoverage.images.length === 0 ? "0" : "3rem"
                  }}>
                  Gallery
                </h2>
                {post.gallery.usesYears && (
                  <div className="years-container">
                    <ul className="years-list">
                      {post.gallery.years.map(year => (
                        <li key={year}>
                          <div className="time-check-container">
                            <div className="time-check">
                              <input
                                onChange={handleYearChange(year)}
                                type="checkbox"
                                checked={years[year]}
                                id={`time-check-${year}.`}
                                value={`year-${year}`}
                              />
                              <label htmlFor={`time-check-${year}.`}>
                                {year}
                              </label>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="column is-3"></div>
            </div>

            <div className="columns has-text-centered">
              <div className="column">
                <GridGallery
                  pages={displayedPhotos}
                  rowsOf={3}
                  toggleDialog={toggleDialog}
                />
                {post.gallery.bottomBlurb && (
                  <h4>{post.gallery.bottomBlurb}</h4>
                )}
              </div>
            </div>
          </section>

          <section className="body-section cta">
            <GetStarted
              sideColumnIs={2}
              centerHeading
              heading={post.getStarted.heading}
              buttons={post.getStarted.buttons}
              paragraph={<p>{post.getStarted.blurb}</p>}
            />
          </section>

          <div>
            <Dialog
              className="community-dialog"
              lazy={false}
              backdropClassName="dark-background"
              portalClassName="show-cursor community-portal"
              isOpen={isDialogOpen}
              onClose={toggleDialog}>
              <CloseIcon onClick={toggleDialog} />
              <DialogGallery
                galleryIndex={galleryIndex}
                images={reactGalleryPhotos(displayedPhotos)}
              />
            </Dialog>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SingleCommunity

export const query = graphql`
  query ($title: String!) {
    singleCommunity: allCommunitiesJson(filter: { title: { eq: $title } }) {
      nodes {
        blurb
        gallery {
          shortcode
          bottomBlurb
          prefix
          usesYears
          years
        }
        getStarted {
          buttons {
            button {
              buttonText
              href
            }
          }
          heading
          blurb
        }
        heading
        id
        mainVideoImagePair {
          youtube
          imageId
          aspectRatio
        }
        mediaCoverage {
          images {
            image
            href
          }
          heading
        }
        metaDescription
        metaTitle
        schemaPage
        searchHeading
        title
      }
    }
  }
`
